.youtube-gallery {
  padding: 20px;
  max-width: 1200px; /* 갤러리의 최대 너비를 설정하여 화면에 맞게 배치 */
  margin: 0 auto; /* 중앙 정렬 */
  background-color: #ffffff; /* 배경색을 흰색으로 설정 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 가벼운 그림자를 추가해 입체감 부여 */
  border-radius: 8px; /* 모서리를 둥글게 설정 */
}

.youtube-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.youtube-table th, 
.youtube-table td {
  border: 1px solid #ddd;
  padding: 12px; /* 셀 패딩을 조금 더 넉넉하게 조정 */
  text-align: left;
  font-size: 1rem; /* 글씨 크기를 조금 키워 가독성을 높임 */
}

.youtube-table th {
  background-color: #4CAF50; /* 기존의 초록색 배경 유지 */
  color: white;
  font-weight: bold;
  text-transform: uppercase; /* 헤더 글자를 대문자로 변환 */
}

.youtube-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.youtube-table tr:nth-child(odd) {
  background-color: #fdfdfd; /* 홀수 행의 배경색을 약간 다르게 설정 */
}

.youtube-table tr:hover {
  background-color: #e1e1e1; /* 마우스 올렸을 때의 색상 변경 */
  transition: background-color 0.2s ease-in-out; /* 배경색 전환을 부드럽게 */
}

/* no 값이 0인 행에 적용될 스타일 */
.highlight {
  background-color: #d4edda !important; /* 우선순위를 높이기 위해 !important 사용 */
}

/* 모바일 환경에서 테이블 가독성 향상을 위한 반응형 스타일 */
@media (max-width: 768px) {
  .youtube-table th, 
  .youtube-table td {
    padding: 10px;
    font-size: 0.9rem; /* 글씨 크기를 조정해 작은 화면에서 가독성을 높임 */
  }
}
