/* components/Header.css */

html, body {
  margin: 0;
  padding: 0;
  height: 100%; /* 전체 높이 차지 */
}

.App {
  height: 100%; /* App 컴포넌트도 전체 높이 차지 */
}

.App-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  background-color: #282c34;
  color: white;
  position: relative;
  height: 60px;
  min-height: 60px; /* 최소 높이 설정 */
  max-height: 60px; /* 최대 높이 설정 */
  overflow: hidden; /* 내부 요소가 높이를 초과하지 않도록 설정 */
}

@media (max-width: 768px) {
  .App-header {
    height: 60px; /* 모바일에서도 동일한 높이 */
  }
}

.left-section {
  flex: 1;
  display: flex;
  align-items: center;
  position: relative; /* 메뉴 위치를 조정하기 위해 position 설정 */
}

.header-title {
  flex: 2;
  text-align: left;
  font-size: 2rem; /* 글자 크기를 줄임 */
  margin: 0; /* 여백 제거 */
}

.menu-icon {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-right: 10px;
}

.menu-icon span {
  width: 25px;
  height: 3px;
  background-color: white;
}

.menu {
  position: absolute;
  top: 20px; /* 햄버거 버튼 바로 아래에 위치 */
  left: 0;
  background-color: #333;
  color: white;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  gap: 10px; /* 버튼 사이의 간격 */
}

.menu-container {
  display: flex; /* Flexbox 사용 */
  flex-direction: row; /* 자식 요소를 가로로 정렬 column row */
  align-items: center; /* 수직 가운데 정렬 */
  gap: 5px; /* 자식 요소 간 간격 */
  padding: 5px; /* 내부 여백 */
  background-color: #333; /* 배경색 설정 */
  border-radius: 5px; /* 모서리를 둥글게 */
  z-index: 1000; /* 다른 요소 위로 표시 */
  position: absolute; /* 다른 요소 위에 표시 */
  left: 0;
  top: 0px;
}

.menu-container input {
  width: 50px; /* 입력 필드의 너비를 50%로 설정 */
  padding: 5px; /* 내부 여백 줄임 */
  font-size: 0.8rem; /* 글씨 크기 줄임 */
  margin: 2px 0; /* 입력 필드 간격 추가 */
  border: 1px solid #ccc; /* 테두리 스타일 */
  border-radius: 4px; /* 둥근 모서리 */
}

.menu-container input:focus {
  border-color: #4CAF50; /* 포커스 시 테두리 색상 변경 */
  outline: none; /* 기본 아웃라인 제거 */
}

.menu-container button {
  display: inline-flex; /* 버튼 내부 텍스트를 가로로 정렬 */
  align-items: center; /* 텍스트 수직 정렬 */
  justify-content: center; /* 텍스트 수평 정렬 */
  background: none;
  border: none;
  color: white;
  padding: 5px 10px; /* 버튼 내부 여백 */
  height: 30px; /* 버튼 높이 */
  cursor: pointer;
  text-align: center;
  border-radius: 3px;
  white-space: nowrap; /* 텍스트가 줄 바뀌지 않도록 설정 */
}

.menu-container button:hover {
  background-color: #555; /* 호버 시 배경색 변경 */
}

.menu button {
  background: none;
  border: none;
  color: white;
  padding: 5px 10px; /* 버튼 크기 조정 */
  cursor: pointer;
  text-align: left;
}

.led-indicator {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: 20px;
  background-color: gray;
  transition: background-color 0.3s ease;
}

.led-on {
  background-color: green;
}

.led-off {
  background-color: red;
}

