/* MachineOverview.css */
/* MachineOverview.css */

.machine-overview {
  padding: 20px;
}

.device-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.device-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 1 calc(25% - 10px);
  max-width: 300px; /* Increase max-width */
  height: 150px; /* Set a fixed height to accommodate content */
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  cursor: pointer;
  overflow: hidden; /* Ensure content stays inside the button */
}

.device-button:hover {
  background-color: #eef;
}

.device-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2px; /* Reduce gap between rows */
  width: 100%;
}

.device-header {
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 5px;
  margin-bottom: 10px;
}

.device-name {
  font-size: 16px;
  font-weight: bold;
}

.device-type {
  font-size: 12px;
  color: gray;
}

.led-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px; 
  margin: 5px 0;
}

.led-label {
  font-size: 14px;
  font-weight: bold;
}

.led-container {
  display: flex;
  justify-content: center;
  gap: 5px;
}

.led {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid #ccc;
}

.led-on {
  background-color: green;
}

.led-off {
  background-color: rgb(210, 210, 210);
}

.temp-humi-row {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  gap: 15px;
  width: 100%;
}

.temp,
.humi {
  font-size: 14px;
  font-weight: bold;
}

.table-header {
  background-color: rgb(210, 210, 210);
  color: black; /* 글자 색상은 필요에 따라 설정 */
}
