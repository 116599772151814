.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 9999; /* z-index 값 설정 */
}

.modal ul {
  list-style: none;
  padding: 0;
}

.modal li {
  padding: 10px;
  cursor: pointer;
}

.modal button {
  margin: 10px;
}

/* 입력창 스타일 */
.modal select,
.modal input {
  width: 100%;
  max-width: 300px; /* 최대 너비 설정 */
  max-height: 40px; /* 최대 높이 설정 */
  padding: 8px;
  font-size: 14px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* select 옵션 크기 조정 */
.modal select option {
  max-width: 300px; /* 옵션 너비 제한 */
  white-space: nowrap; /* 텍스트 줄바꿈 방지 */
  overflow: hidden; /* 넘치는 텍스트 숨기기 */
  text-overflow: ellipsis; /* 넘치는 텍스트 말줄임 표시 */
}

.selected-day {
  background: #007bff;
  color: #fff;
}
