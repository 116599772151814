/* ImageGallery.css */

.image-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 100%; /* 화면의 최대 너비를 100%로 설정 */
    margin: 20px auto; /* 가운데 정렬 */
  }
  
  .image-container {
    margin: 10px;
    width: 100%; /* 전체 너비를 사용 */
    max-width: 350px; /* 모바일에서의 이미지 컨테이너의 최대 너비 */
    align-items: center;
  }
  
  .image {
    width: 100%; /* 이미지가 컨테이너 너비에 맞게 설정 */
  }
  
  .image-name, .image-description {
    width: 100%;
    word-wrap: break-word; /* 텍스트를 이미지 폭에 맞추기 위해 단어 단위로 줄 바꿈 */
    margin-top: 10px; /* 텍스트와 이미지 사이의 간격 설정 */
    font-size: 1.2rem; /* 글자 크기 확대 */
  }
  
  .image-link {
    font-size: 1.0rem; /* 구매 링크의 글자 크기 줄임 */
    display: flex;
    align-items: center;
    justify-content: center; /* 가운데 정렬 */
  }
  
  .purchase-icon {
    width: 100px; /* 아이콘 크기를 줄임 */
    height: 20px;
    margin-left: 5px;
    cursor: pointer;
  }
  
  /* 반응형 미디어 쿼리로 큰 화면에서 더 큰 레이아웃을 제공 */
  @media (min-width: 768px) {
    .App-header {
      font-size: calc(14px + 2vmin); /* 더 큰 화면에서는 텍스트를 더 키움 */
      min-height: 15vh; /* 더 큰 화면에서 헤더 크기를 줄임 */
    }
  
    .image-container {
      max-width: 400px; /* PC 화면에서 이미지 컨테이너 크기를 확장 */
    }
  
    .image-name, .image-description {
      font-size: 1.4rem; /* 큰 화면에서는 글자 크기 확장 */
    }
  }
  
  @media (min-width: 1024px) {
    .image-container {
      width: 23%; /* 큰 화면에서 4개의 이미지가 한 줄에 배치되도록 설정 */
      max-width: 23%; /* 한 줄에 4개가 들어가도록 설정 */
    }
  
    .image-name, .image-description {
      font-size: 1.2rem; /* PC 화면에서 설명 글자 크기 확장 */
    }
  }
  
  footer {
    background-color: #282c34;
    color: white;
    padding: 20px;
    text-align: center;
    width: 100%;
  }
  