/* components/DeviceDetail.css */


.device-detail {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .led-container {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }
  
  .device-detail .led {
    width: 40px; /* 두 배 크기 */
    height: 40px; /* 두 배 크기 */
    border-radius: 50%;
    background-color: gray;
    transition: background-color 0.3s;
  }
  
  .device-detail .led-on {
    background-color: green;
  }
  
  .device-detail .led-off {
    background-color: rgb(212, 211, 211);
  }
  
  .switch-container {
    display: flex;
    justify-content: center; /* 스위치 중앙 정렬 */
    gap: 10px;
    margin-top: 10px;
  }
  
  .switch {
    position: relative;
    width: 80px; /* 80% 크기 */
    height: 40px; /* 80% 크기 */
    background-color: gray;
    border-radius: 20px; /* 높이에 따라 자동 조정 */
    cursor: pointer;
    transition: background-color 0.3s;
    border: none;
  }
  
  .switch::before {
    content: '';
    position: absolute;
    top: 4px; /* 높이에 맞춰 조정 */
    left: 4px;
    width: 32px; /* 80% 크기 */
    height: 32px; /* 80% 크기 */
    background-color: white;
    border-radius: 50%;
    transition: transform 0.3s;
  }  
  
  .switch-on {
    background-color: green;
  }
  
  .switch-on::before {
    transform: translateX(40px);  /* 80% 크기 */
  }
  
  .switch-off {
    background-color: rgb(210, 210, 210);
  }
  